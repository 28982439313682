import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'react-router-redux'
import loadable from 'loadable-components'

import Loading from 'components/Common/Loading'

const Checkout = loadable(() => import(/* webpackChunkName: "Checkout" */ 'containers/Checkout'), {
  LoadingComponent: () => <Loading />,
  modules         : [ 'containers/Checkout' ]
})

export default history => (
  <ConnectedRouter history={history}>
    <Switch>
      {/* <Route component={Checkout} exact path='/' /> */}
      <Route component={Checkout} exact path='/checkout' />
    </Switch>
  </ConnectedRouter>
)
