import axios from 'axios'
require('axios-debug-log')

// const { REACT_APP_REST_API_LOCATION, REACT_APP_API_VERSION } = process.env

// export const baseURL = REACT_APP_REST_API_LOCATION + '/api/' + REACT_APP_API_VERSION + '/'
let _source, beforeRoute

function serialize(obj) {
  var str = []
  for (var p in obj) if(obj.hasOwnProperty(p)) str.push(encodeURIComponent(p) + '=' + obj[p])

  return str.join('&')
}

export class Request {
  constructor(url, origin) {
    this.url = url
    this.origin = origin
    this.http()
  }

  http = function() {
    // let url = typeof window === 'undefined' ? this.url : (this.url || 'https://betaapi.imagina.pe')
    let url =  typeof window !== 'undefined' && this.origin === 'local' ? window.location.origin : 'https://api.imagina.pe'

    const version = process.env.REACT_APP_API_VERSION || 'v1'

    _source = axios.CancelToken.source()

    let instance = axios.create({
      baseURL    : url + '/api/' + version + '/',
      cancelToken: _source.token,
      mode       : 'no-cors'
    })

    // instance.defaults.headers.common['Authorization'] = localStorage.getItem('token')

    // return instance
    this.request = instance
  }

  Get = route  => {
    return new Promise((resolve, reject) => {
      verifyRequestCancel(route)

      this.request
        .get(route)
        .then(res => {
          beforeRoute = null
          resolve(res.data)
        })
        .catch(e => {
          beforeRoute = null
          reject({ type: axios.isCancel(e) ? 'cancel' : 'err', ...e })
        })
    })
  }

  Put = (route, json = {}) => {
    return new Promise((resolve, reject) => {
      verifyRequestCancel(route)
      this.request
        .put(route, json)
        .then(res => {
          beforeRoute = null
          resolve(res.data)
        })
        .catch(e => {
          beforeRoute = null
          reject({ type: axios.isCancel(e) ? 'cancel' : 'err', ...e })
        })
    })
  }
}

export function Delete(route, json = {}) {
  return new Promise((resolve, reject) => {
    verifyRequestCancel(route)
    http()
      .delete(route, { data: json })
      .then(res => resolve(res.data))
      .catch(e => {
        reject({ type: axios.isCancel(e) ? 'cancel' : 'err', ...e })
      })
  })
}

export function Patch(route, json = {}) {
  return new Promise((resolve, reject) => {
    verifyRequestCancel(route)
    http()
      .patch(route, json)
      .then(res => resolve(res.data))
      .catch(e => {
        reject({ type: axios.isCancel(e) ? 'cancel' : 'err', ...e })
      })
  })
}

export function Post(route, json = {}) {
  return new Promise((resolve, reject) => {
    verifyRequestCancel(route)
    http()
      .post(route, json)
      .then(res => resolve(res.data))
      .catch(e => {
        reject({ type: axios.isCancel(e) ? 'cancel' : 'err', ...e })
      })
  })
}

export function GetList(product, query) {
  return Get(product + '/?' + serialize(query))
}

function verifyRequestCancel(route) {
  if(beforeRoute === route) {
    if(_source !== undefined) _source.cancel('Operation canceled due to new request.')
  } else {
    beforeRoute = route
  }
}
