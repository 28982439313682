export const getMessageError = e => {
  let currentMessage = ''

  if(e.response) {
    const { message = '', errors } = e.response.data

    if(message) currentMessage = message
    else if(errors) for (const key of Object.keys(errors)) currentMessage += errors[key].join(',') + '\n'
  } else {
    currentMessage = e.message
  }

  return currentMessage
}

export const formatNumber = {
  separador : ',', // separador para los miles
  sepDecimal: '.', // separador para los decimales
  simbol    : '',
  formatear : function(num) {
    num += ''
    var splitStr = num.split('.')
    var splitLeft = splitStr[0]
    var splitRight = splitStr.length > 1 ? this.sepDecimal + splitStr[1] : ''
    var regx = /(\d+)(\d{3})/
    while (regx.test(splitLeft))
      splitLeft = splitLeft.replace(regx, '$1' + this.separador + '$2')

    return this.simbol + splitLeft + splitRight
  },
  set: function(num, simbol) {
    this.simbol = simbol || ''

    return this.formatear(num)
  }
}

export const capitalizeString = (cadena = '') => {
  return cadena.charAt(0).toUpperCase().concat(cadena.slice(1).toLowerCase())
}

// @deprecado
// export function queryStringToJSON(query) {
//   var pairs = query.slice(1).split('&')

//   var result = {}
//   pairs.forEach(function(pair) {
//     pair = pair.split('=')
//     result[pair[0]] = decodeURIComponent(pair[1] || '')
//   })

//   return JSON.parse(JSON.stringify(result))
// }

export function queryStringToJSON(queryString) {
  if(queryString.indexOf('?') > -1) queryString = queryString.split('?')[1]

  var pairs = queryString.split('&')
  var result = {}
  pairs.forEach(function(pair) {
    pair = pair.split('=')
    result[pair[0]] = decodeURIComponent(pair[1] || '')
  })

  return result
}

export function getQueryParameters(str) {
  // .map(
  //   function(n) {
  //     return (n = n.split('=')), (this[n[0]] = n[1]), this
  //   }.bind({})
  // )[0]
  return (str || document.location.search)
    .replace(/(^\?)/, '')
    .split('&')
    .map(item => {
      const [ key, value ] = item.split('=')

      return { [key]: value }
    })
    .reduce((obj, item) => {
      const key = Object.keys(item)[0]
      obj[key] = item[key]

      return obj
    }, {})
}
