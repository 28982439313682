import Duck from 'extensible-duck'
import produce from 'immer'
import { createSelector } from 'reselect'
import { formatNumber } from 'utils'

import base from './base'

export default base({
  namespace   : 'enova',
  store       : 'checkout',
  initialState: {
    id        : 0,
    cart      : [],
    first_step: {
      customer      : null,
      order_customer: {
        // id           : null,
        first_name   : null,
        first_surname: null,
        last_surname : null,
        email        : null,
        document     : null,
        type_document: 1,
        phone        : null
      }
    },
    second_step: {
      id     : null,
      billing: {
        // id               : null, // (orderBilling (id de la fotocopia) si pones null crea)
        bill            : null, // (id del comprobante por ahora nulo ya que no hay perfil en uso ni perfil de factura en uso)
        type_bill       : null, // invoice or bill
        company_name    : '',
        type_document   : 2, // (id de RUC)
        document_store  : '',
        fiscal_address  : '',
        shop_address    : null,
        type_address_fac: 1
      },
      payment_method : null,
      payment_gateway: null
    },
    finish_step: {
      data    : null,
      status  : null,
      settings: null
    },
    email          : null,
    total          : 0,
    firstStatus    : 'EDIT',
    secondStatus   : 'READY',
    step           : null,
    type_documents : [],
    payment_methods: [],
    type_bills     : []
  }
})
  .extend({
    types: [
      'PUT_STEP',
      'PUT_FULFILLED_STEP'
    ],
    reducer: (state, action, { types }) =>
      produce(state, draft => {
        switch (action.type) {
          case types.PUT_FULFILLED_STEP:

            const { step, payload: { payment_gateway, status } } = action
            if(step === 'finish')
              draft.finish_step = {
                ...payment_gateway,
                status,
                data: {
                  ...payment_gateway.data,
                  operation: {
                    ...payment_gateway.data.operation,
                    amount: formatNumber.set(payment_gateway.data.operation.amount)
                  }
                }
              }

            console.log('draft.finish_step', draft.finish_step)

            draft.status = 'SAVE_STEP'
            draft.step = step
            // draft.order_hash = payload.order_hash

            return
          case types.FETCH_FAILURE_CHECKOUT:
            draft.status = 'CHECKOUT_FAILURE'
          default:
            return
        }
      }),
    creators: ({ types: { FETCH, UPDATE, FETCH_FOR_PATH, PUT_STEP } }) => ({
      getCheckout    : id => ({ type: FETCH, id }),
      updateForm     : payload => ({ type: UPDATE, payload }),
      getItemsForPath: path => ({ type: FETCH_FOR_PATH, path }),
      stepCheckout   : (step, params) => ({ type: PUT_STEP, step, params })
    }),
    selectors: ({ store }) => ({
      getProducts    : state => state[store].entities.products,
      getProductsById: new Duck.Selector(({ getOrders, getLineItems, getProducts }) =>
        createSelector([ getOrders, getLineItems, getProducts ], (orders, lineItems, products) => id => {
          return orders[id] ? orders[id].lineItems.map(lineItemId => products[lineItems[lineItemId].product]) : []
        })
      )
    })
  })
