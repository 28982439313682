import './sources/sass/app.scss'

import React from 'react'

import store, { history } from './store/configureStore'
import createRoutes from './routes'
import Root from './containers/Root'

import ReactPixel from 'react-facebook-pixel'

// em: 'grover.jtb@@email.com'
const advancedMatching = { } // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
const options = {
  autoConfig: true, 	// set pixel's autoConfig
  debug     : true 		// enable logs
}

ReactPixel.init('2345642175656164', advancedMatching, options)

if(process.env.NODE_ENV === 'test') {
  const { whyDidYouUpdate } = require('why-did-you-update')
  whyDidYouUpdate(React)
}

const routes = createRoutes(history)

export default () => <Root store={store}>{routes}</Root>
