import { call, put, takeEvery } from 'redux-saga/effects'
import axios from 'axios'

import { Get, Put } from 'lib/Request/server'
import { Put as PutLocal } from 'lib/Request/local'
import checkoutDucks from 'reducers/checkout'
import { getMessageError, getQueryParameters } from 'utils'

const { types } = checkoutDucks

function* getCheckout(action) {
  try {
    yield put({ type: types.FETCH_PENDING })

    const payload = yield call(Get, `checkout/${action.id}/`)
    yield put({
      type   : types.FETCH_FULFILLED,
      payload: {
        ...payload,
        cart      : payload.cart || [],
        first_step: {
          ...payload.first_step,
          order_customer: payload.first_step.order_customer || {
            first_name   : null,
            first_surname: null,
            last_surname : null,
            email        : null,
            document     : null,
            type_document: 1,
            phone        : null
          }
        },
        second_step: {
          ...payload.second_step,
          billing: payload.second_step.billing || {
            // id               : null, // (orderBilling (id de la fotocopia) si pones null crea)
            bill            : null, // (id del comprobante por ahora nulo ya que no hay perfil en uso ni perfil de factura en uso)
            type_bill       : null, // invoice or bill
            company_name    : null,
            type_document   : 1, // (id de RUC)
            document_store  : null,
            fiscal_address  : null,
            shop_address    : null,
            type_address_fac: 1
          }
        }
      }
    })
  } catch (e) {
    // yield put({
    //   type   : types.FETCH_FULFILLED,
    //   payload: {
    //     id    : 51,
    //     status: 'borrador',
    //     cart  : {
    //       id   : 1,
    //       items: [
    //         {
    //           name    : 'Edificio Ocean',
    //           image   : 'https://www.imagina.com.pe/uploads/portadas/fachada-concept-pacifico-min.jpg',
    //           price   : '100.98',
    //           detail  : 'Un producto con los mejores sabores',
    //           sku     : 'd123EDD',
    //           quantity: '1'
    //         }
    //       ]
    //     },
    //     first_step: {
    //       customer      : null,
    //       order_customer: {},
    //       is_guest      : false
    //     },
    //     second_step: {
    //       id             : 51,
    //       payment_method : null,
    //       payment_gateway: null,
    //       billing        : {},
    //       total          : '0.00',
    //       email          : null
    //     },
    //     email: null,
    //     total: 100.98
    //   }
    // })
    switch (e.type) {
      case 'cancel':
        yield put({ type: types.FETCH_CANCEL })
        break
      default:
        yield put({
          type : types.FETCH_FAILURE,
          error: 'error' // getMessageError(e)
        })
        break
    }
  }
}

function* getItemsForPath(action) {
  try {
    yield put({ type: types.FETCH_FOR_PATH_PENDING })
    // const payload = yield call(Get, `${action.path}/`)
    let payload = yield call(Get, `${action.path}`)

    if(action.path === 'type_documents' && payload && payload.items && !payload.items.length)
      payload = {
        ...payload,
        items: [
          { id: 1, name: 'DNI', max_length: 8 }
        ]
      }

    yield put({
      type   : types.FETCH_FOR_PATH_FULFILLED,
      payload: {
        [action.path]: payload.items ? payload.items : []
      }
    })
  } catch (e) {
    yield put({ type: types.FETCH_FOR_PATH_FAILURE, error: getMessageError(e) })
  }
}

function* stepCheckout(action) {
  try {
    const { step, params } = action
    // const { id, auth_token } = yield select(user.selectors.user)

    yield put({ type: types.PUT_PENDING })

    let url2json = getQueryParameters(window.location.search)

    // get from url parms token, auth_token
    let token_block = params.token ?
      params.token :
      url2json['token_hash'] ?
        url2json['token_hash'] :
        url2json['token']

    const pathCheckout = step === 'finish' ? `checkout/${token_block}/` : `checkout/${step}/${token_block}/`

    let payload = null
    if(step === 'finish')
      payload = yield call(PutLocal, `checkout/pasarela/${token_block}/`, params.data)
    else
      payload = yield call(Put, pathCheckout, params.data)

    const mStep = step === 'first-step' ? 'first_step' : step === 'second-step' ? 'second_step' : 'finish'

    yield put({ type: types.PUT_FULFILLED_STEP, step: mStep, payload })
  } catch (e) {
    console.log('ERROR', e)
    debugger
    if(window !== 'undefined') alert('Tokene no valido o a expirado')
    yield put({ type: types.PUT_FAILURE, error: getMessageError(e) })
  }
}

export default [
  takeEvery(types.FETCH, getCheckout),
  takeEvery(types.FETCH_FOR_PATH, getItemsForPath),
  takeEvery(types.PUT_STEP, stepCheckout)
]
